import { ref, get } from 'firebase/database';
import { auth, database } from './firebaseConfig';
import { useState, useEffect } from 'react';
import * as React from "react";

const EmailList: React.FC = () => {
  const [emails, setEmails] = useState<string[]>([]);

  const fetchEmails = async () => {
    const user = auth.currentUser;
    if (user) {
      const businessRef = ref(database, `businesses/${user.uid}/emails`);
      const snapshot = await get(businessRef);
      if (snapshot.exists()) {
        const emailData = snapshot.val();
        const emailList = Object.values(emailData);
        setEmails(emailList as string[]);
      }
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  return (
    <div className="bg-blue-50 flex flex-col h-screen">
      {/* Header */}
      <header className="bg-blue-300 text-white text-center py-4">
        <h1 className="text-xl font-bold">Databáza zákazníkov</h1>
      </header>

      {/* Sekcia emailovej databázy */}
      <div id="email-database" className="bg-white shadow-md mx-4 mt-4 p-4 rounded-lg flex-grow overflow-y-auto">
        <h2 className="text-lg font-bold text-gray-800">Emailová Databáza</h2>

        {/* Zoznam emailov */}
        <div className="mt-4">
          <ul>
            {emails.map((email, index) => (
              <li key={index} className="bg-gray-100 p-2 my-2 rounded-lg shadow-sm">
                {email}
              </li>
            ))}
          </ul>
        </div>

        {/* Tlačidlo na export */}
        <div className="flex justify-center mt-6">
          <button className="bg-gray-400 text-white py-2 px-6 rounded-full shadow-md cursor-not-allowed" disabled>
            Exportovať (Pracujeme na tom)
          </button>
        </div>
      </div>

      {/* Menu */}
      <nav className="bg-white shadow-md fixed bottom-0 left-0 right-0 flex justify-around py-2">
        <a href="/business/settings" className="flex flex-col items-center text-gray-700">
          <img src="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/icons/gear.svg" alt="Nastavenia" className="w-6 h-6" />
          <span className="text-sm">Nastavenia</span>
        </a>
        <a href="/business/admin" className="flex flex-col items-center text-gray-700">
          <img src="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/icons/house-door.svg" alt="Domov" className="w-6 h-6" />
          <span className="text-sm">Domov</span>
        </a>
        <a href="/business/email-list" className="flex flex-col items-center text-blue-600">
          <img src="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/icons/envelope.svg" alt="Emailová Databáza" className="w-6 h-6" />
          <span className="text-sm">Zákazníci</span>
        </a>
      </nav>
    </div>
  );
};

export default EmailList;
