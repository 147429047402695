import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signInWithGoogle, auth, database, handleRedirectResult } from './firebaseConfig';
import { ref, get, set } from 'firebase/database';
import { UserCredential, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { Loading } from './Loading';

const Business: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const uid = location.pathname.split('/business/')[1];

  useEffect(() => {
    const handleSignIn = async (user: UserCredential['user']) => {
      try {
        if (user) {
          const userId = auth.currentUser?.uid;
          if (userId) {
            const email = user.email;
            const userRef = ref(database, `users/${userId}/businesses/${uid}`);
            const snapshot = await get(userRef);
            if (!snapshot.exists()) {
              await set(userRef, { points: 0 });

              // Add the user's email to the business only if the user was added to the business
              if (email) {
                await fetch('https://us-central1-loyalperks-1.cloudfunctions.net/api/addBusinessEmail', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ email, businessId: uid }),
                });
              }
            }
            await fetch('https://us-central1-loyalperks-1.cloudfunctions.net/api/user/generateQRCode', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ user_id: userId }),
            });
            navigate('/user/dashboard'); // Redirect to dashboard after successful registration
          }
        }
      } catch (error) {
        console.error('Google sign-in error:', error);
      }
    };

    const initAuth = async () => {
      try {
        await handleRedirectResult(); // Handle the redirect result to check for user login
        await setPersistence(auth, browserSessionPersistence);
        const unsubscribe = auth.onAuthStateChanged(user => {
          if (user) {
            handleSignIn(user);
          } else {
            signInWithGoogle(); // Initiates Google sign-in via redirect if not signed in
          }
        });
        return unsubscribe;
      } catch (error) {
        console.error('Google sign-in error:', error);
      }
    };

    let unsubscribe: (() => void) | undefined;

    initAuth().then((unsub) => {
      unsubscribe = unsub;
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [uid, navigate]);

  return (
    <Loading />
  );
};

export default Business;
