import * as React from "react";
import { useState, useEffect } from 'react';
import { auth, database } from './firebaseConfig';
import { ref, get } from 'firebase/database';
import { QrReader } from 'react-qr-reader';
import { LoadingStartStop } from "./Loading";

const AdminInterface: React.FC = () => {
  const [userId, setUserId] = useState<string>('');
  const [userPoints, setUserPoints] = useState<number | null>(null);
  const [incrementValue, setIncrementValue] = useState<number | null>(null);
  const [businessId, setBusinessId] = useState<string>('');
  const [showQrReader, setShowQrReader] = useState<boolean>(false);
  const [userFound, setUserFound] = useState<boolean>(false);
  const [customerEmail, setCustomerEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchIncrementValue = async () => {
      const user = auth.currentUser;
      if (user) {
        const businessRef = ref(database, `businesses/${user.uid}/points`);
        try {
          const snapshot = await get(businessRef);
          if (snapshot.exists()) {
            const pointsValue = snapshot.val();
            setIncrementValue(pointsValue);
            setBusinessId(user.uid);
          } else {
            setIncrementValue(null);
          }
        } catch (error) {
          console.error('Error fetching increment value:', error);
          setIncrementValue(null);
        }
      }
    };

    fetchIncrementValue();
  }, []);

  const handleUserIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserId(e.target.value);
  };

  const fetchUserPoints = async () => {
    if (userId && businessId) {
      setLoading(true);
      try {
        const response = await fetch(
          `https://us-central1-loyalperks-1.cloudfunctions.net/api/getUserPoints?userId=${userId}&businessId=${businessId}`
        );
        const data = await response.json();
        if (response.ok) {
          setUserPoints(data.points);
          setUserFound(true);
          setCustomerEmail(data.email);
          setError('');
        } else {
          setUserPoints(null);
          setUserFound(false);
          setError(data.error || 'Failed to fetch user points.');
        }
      } catch (error) {
        console.error('Error fetching user points:', error);
        setUserPoints(null);
        setUserFound(false);
        setError('Failed to fetch user points.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePointsChange = async (newPoints: number) => {
    if (userId && businessId) {
      try {
        await fetch('https://us-central1-loyalperks-1.cloudfunctions.net/api/updateUserPoints', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId, businessId, points: newPoints }),
        });
        setUserPoints(newPoints);
      } catch (error) {
        console.error('Error updating user points:', error);
        setError('Failed to update user points.');
      }
    }
  };

  const incrementPoints = async () => {
    if (userPoints !== null && incrementValue !== null && incrementValue !== 0) {
      const newPoints = userPoints + incrementValue;
      await handlePointsChange(newPoints);
    }
  };

  const resetPoints = async () => {
    await handlePointsChange(0);
  };

  const toggleQrReader = () => {
    setShowQrReader(prevState => !prevState);
  };

  const handleScan = (data: string | null) => {
    if (data) {
      try {
        const parsedData = JSON.parse(data);
        if (parsedData && parsedData.user_id) {
          setUserId(parsedData.user_id); // Update userId state with scanned user_id
          setShowQrReader(false); // Close the QR scanner
        }
        setError('');
      } catch (error) {
        console.error('Error parsing scanned QR code data:', error);
        setError('Failed to parse QR code data.');
      }
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserPoints(); // Automatically trigger search when userId is updated
    }
  }, [userId]);

  return (
    <div className="bg-blue-50 flex flex-col h-screen">
      {/* Header */}
      <header className="bg-blue-300 text-white text-center py-4">
        <h1 className="text-xl font-bold">Domov</h1>
      </header>

      {/* Vyhľadávací panel */}
      <div className="flex items-center bg-white shadow-md mx-4 mt-4 p-2 rounded-lg">
        <input
          type="text"
          placeholder="Hľadať používatela"
          className="flex-grow px-4 py-2 rounded-l-lg outline-none"
          value={userId}
          onChange={handleUserIdChange}
        />
        <button
          onClick={fetchUserPoints}
          className="bg-blue-300 text-white px-4 py-2 rounded-r-lg"
        >
          <img
            src="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/icons/search.svg"
            alt="Hľadať"
            className="w-5 h-5"
          />
        </button>
      </div>

      {/* Loading component - StartStop */}
      <LoadingStartStop isLoading={loading} />

      {/* User Details or Error Message */}
      {userFound && !loading && (
        <div id="user-details" className="bg-white shadow-md mx-4 mt-4 p-4 rounded-lg">
          <h2 className="text-lg font-bold text-gray-800" id="user-name">{customerEmail}</h2>
          <p className="text-gray-600" id="user-points">
            Body: <span id="points-count">{userPoints ?? 'Loading...'}</span>
          </p>
          <div className="mt-4 flex space-x-4">
            <button
              onClick={incrementPoints}
              className="bg-green-500 text-white px-4 py-2 rounded-lg flex items-center"
              disabled={userPoints === null || incrementValue === null || incrementValue === 0}
            >
              <img
                src="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/icons/plus-circle.svg"
                alt="Navýšiť body"
                className="w-5 h-5 mr-2"
              />
              Navýšiť body
            </button>
            <button
              onClick={resetPoints}
              className="bg-red-500 text-white px-4 py-2 rounded-lg flex items-center"
              disabled={userPoints === null || userPoints === 0}
            >
              <img
                src="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/icons/x-circle.svg"
                alt="Vynulovať body"
                className="w-5 h-5 mr-2"
              />
              Vynulovať body
            </button>
          </div>
        </div>
      )}

      {/* Error message */}
      {error && (
        <div className="bg-white shadow-md mx-4 mt-4 p-4 rounded-lg">
          <p className="text-red-600">{error}</p>
        </div>
      )}

      {/* Tlačidlo na skenovanie/zobrazenie QR kódov */}
      <div className="flex justify-center mt-6">
        <button
          onClick={toggleQrReader}
          className="bg-blue-300 text-white py-2 px-6 rounded-full shadow-md flex items-center"
        >
          <img
            src="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/icons/qr-code.svg"
            alt="QR Kód Skener"
            className="w-5 h-5 mr-2"
          />
          {showQrReader ? 'Zatvoriť' : 'Skenovať QR Kód'}
        </button>
      </div>

      {showQrReader && (
        <QrReader
          scanDelay={1000}
          onResult={(result, error) => {
            if (result) {
              handleScan(result.getText());
            }

            if (error) {
              console.info(error);
            }
          }}
          constraints={{ facingMode: 'environment' }}
          containerStyle={{ width: '100%' }}
        />
      )}

      {/* Menu */}
      <nav className="bg-white shadow-md fixed bottom-0 left-0 right-0 flex justify-around py-2">
        <a href="/business/settings" className="flex flex-col items-center text-gray-700">
          <img src="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/icons/gear.svg" alt="Nastavenia" className="w-6 h-6" />
          <span className="text-sm">Nastavenia</span>
        </a>
        <a href="/business/admin" className="flex flex-col items-center text-blue-600">
          <img src="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/icons/house-door.svg" alt="Domov" className="w-6 h-6" />
          <span className="text-sm">Domov</span>
        </a>
        <a href="/business/email-list" className="flex flex-col items-center text-gray-7000">
          <img src="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/icons/envelope.svg" alt="Emailová Databáza" className="w-6 h-6" />
          <span className="text-sm">Zákazníci</span>
        </a>
      </nav>

    </div>

    
  );
};

export default AdminInterface;
