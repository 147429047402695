import React, { useEffect } from 'react';
import { signOut } from './firebaseConfig';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Loading } from './Loading';

const AdminLogout: React.FC = () => {
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  useEffect(() => {
    const handleSignOut = async () => {
      try {
        await signOut();
        navigate('/business/auth'); // Redirect to login page after successful logout
      } catch (error) {
        console.error('Error signing out:', error);
      }
    };

    handleSignOut();
  }, [navigate]);

  return (
    <Loading />
  );
};

export default AdminLogout;
