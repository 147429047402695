import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, AuthError } from 'firebase/auth';
import { ref, set, update } from 'firebase/database';
import { auth, database } from './firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { LoadingStartStop } from './Loading';

const AuthComponent: React.FC = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [step, setStep] = useState(1);
    const [points, setPoints] = useState(0);
    const [isRegistering, setIsRegistering] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);

    const handleRegister = async (e: React.FormEvent) => {
        e.preventDefault();
        setErrorMessage('');

        if (password !== confirmPassword) {
            setErrorMessage('Heslá sa nezhodujú');
            return;
        }

        if (!isTermsAccepted) {
            setErrorMessage('Musíte súhlasiť s obchodnými podmienkami');
            return;
        }

        setIsLoading(true);

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const userId = userCredential.user.uid;
            const now = new Date();
            const registrationDate = now.toISOString();

            await set(ref(database, `businesses/${userId}`), {
                email,
                businessName,
                step: 2,
                registrationDate: registrationDate, 
            });

            await fetch('https://us-central1-loyalperks-1.cloudfunctions.net/api/business/generateQRCode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: userId,
                }),
            });

            await signInWithEmailAndPassword(auth, email, password);

            setStep(2);
            setIsLoading(false);
        } catch (err) {
            handleAuthError(err as AuthError);
            setIsLoading(false);
        }
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setErrorMessage('');
        setIsLoading(true);

        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/business/admin'); // Presmerovanie po prihlásení
            setIsLoading(false);
        } catch (err) {
            handleAuthError(err as AuthError);
            setIsLoading(false);
        }
    };

    const handleSkipStep2 = async () => {
        const userId = auth.currentUser?.uid;

        if (userId) {
            setIsLoading(true);
            try {
                await set(ref(database, `businesses/${userId}`), {
                    points: 0,
                    step: 2,
                });

                setStep(2);
                navigate('/business/admin'); // Presmerovanie po preskočení kroku 2
                setIsLoading(false);
            } catch (error) {
                setErrorMessage('Nastala chyba pri preskakovaní kroku');
                setIsLoading(false);
            }
        }
    };

    const handleSetPoints = async (e: React.FormEvent) => {
        e.preventDefault();
        const userId = auth.currentUser?.uid;

        if (userId) {
            setIsLoading(true);
            try {
                await update(ref(database, `businesses/${userId}`), {
                    points,
                    step: 3,
                });
                navigate('/business/admin'); // Presmerovanie po preskočení kroku 2
                setIsLoading(false);
            } catch (error) {
                setErrorMessage('Nastala chyba pri nastavovaní bodov');
                setIsLoading(false);
            }
        }
    };

    const handleAuthError = (error: AuthError) => {
        switch (error.code) {
            case 'auth/email-already-in-use':
                setErrorMessage('Email už existuje');
                break;
            case 'auth/invalid-email':
                setErrorMessage('Neplatný email');
                break;
            case 'auth/operation-not-allowed':
                setErrorMessage('Operácia nie je povolená');
                break;
            case 'auth/weak-password':
                setErrorMessage('Slabé heslo');
                break;
            default:
                setErrorMessage('Nastala chyba pri autentifikácii');
                break;
        }
    };

    return (
        <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded shadow-md">
            <LoadingStartStop isLoading={isLoading} />
            <div className="mb-4">
                <button
                    onClick={() => setIsRegistering(true)}
                    className={`w-1/2 py-2 px-4 ${isRegistering ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'} rounded-l-md`}
                >
                    Registrácia
                </button>
                <button
                    onClick={() => setIsRegistering(false)}
                    className={`w-1/2 py-2 px-4 ${!isRegistering ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'} rounded-r-md`}
                >
                    Prihlásenie
                </button>
            </div>

            {isRegistering ? (
                step === 1 ? (
                    <>
                        <h2 className="text-2xl font-semibold mb-4">Registrácia</h2>
                        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
                        <form onSubmit={handleRegister}>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
                                <input
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Heslo:</label>
                                <input
                                    id="password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Potvrdenie hesla:</label>
                                <input
                                    id="confirmPassword"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="businessName" className="block text-sm font-medium text-gray-700">Názov prevádzky:</label>
                                <input
                                    id="businessName"
                                    type="text"
                                    value={businessName}
                                    onChange={(e) => setBusinessName(e.target.value)}
                                    required
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div className="mb-4">
                                <input
                                    id="terms"
                                    type="checkbox"
                                    checked={isTermsAccepted}
                                    onChange={(e) => setIsTermsAccepted(e.target.checked)}
                                    className="mr-2"
                                />
                                <label htmlFor="terms" className="text-sm font-medium text-gray-700">
                                    Súhlasím s{' '}
                                    <a href="/terms.pdf" target="_blank" className="text-indigo-600 underline">
                                        obchodnými podmienkami
                                    </a>
                                </label>
                            </div>
                            <button
                                type="submit"
                                className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Registrovať
                            </button>
                        </form>
                    </>
                ) : (
                    <>
                        <h2 className="text-2xl font-semibold mb-4">Nastavenie počtu bodov</h2>
                        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
                        <form onSubmit={handleSetPoints}>
                            <div className="mb-4">
                                <label htmlFor="points" className="block text-sm font-medium text-gray-700">Zadajte počet bodov na nastavenie:</label>
                                <input
                                    id="points"
                                    type="number"
                                    value={points}
                                    onChange={(e) => setPoints(parseInt(e.target.value))}
                                    required
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Nastaviť počet bodov
                            </button>
                        </form>
                        <button
                            onClick={handleSkipStep2}
                            className="mt-2 w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                            Preskočiť
                        </button>
                    </>
                )
            ) : (
                <>
                    <h2 className="text-2xl font-semibold mb-4">Prihlásenie</h2>
                    {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
                    <form onSubmit={handleLogin}>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
                            <input
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Heslo:</label>
                            <input
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Prihlásiť
                        </button>
                    </form>
                </>
            )}
        </div>
    );
};

export default AuthComponent;
