// src/UserDashboard.tsx

import React, { useEffect, useState } from 'react';
import { ref, onValue, off } from 'firebase/database';
import { auth, database } from './firebaseConfig';

interface BusinessData {
  businessId: string;
  businessName: string;
  points: number;
}

const UserDashboard: React.FC = () => {
  const [businesses, setBusinesses] = useState<BusinessData[]>([]);
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    const userQrCodeRef = ref(database, `users/${userId}/qrCodeUrl`);
    const userBusinessesRef = ref(database, `users/${userId}/businesses`);

    const handleBusinessSnapshot = (userSnapshot: any) => {
      const businessesData: BusinessData[] = [];
      const businessPromises: Promise<void>[] = [];

      userSnapshot.forEach((childSnapshot: any) => {
        const businessId = childSnapshot.key;
        const points = childSnapshot.val().points;

        if (businessId) {
          const businessRef = ref(database, `businesses/${businessId}`);

          const businessPromise = new Promise<void>((resolve) => {
            onValue(businessRef, (businessSnapshot) => {
              const businessName = businessSnapshot.val().businessName;
              businessesData.push({
                businessId,
                businessName,
                points,
              });
              resolve();
            });
          });

          businessPromises.push(businessPromise);
        }
      });

      Promise.all(businessPromises).then(() => {
        setBusinesses(businessesData);
      });
    };

    const handleQrCodeSnapshot = (snapshot: any) => {
      const data = snapshot.val();
      if (data) {
        setQrCodeUrl(data);
      }
    };

    onValue(userBusinessesRef, handleBusinessSnapshot);
    onValue(userQrCodeRef, handleQrCodeSnapshot);

    return () => {
      off(userBusinessesRef, 'value', handleBusinessSnapshot);
      off(userQrCodeRef, 'value', handleQrCodeSnapshot);
    };
  }, []);

  return (
    <div className="bg-blue-50 min-h-screen flex flex-col">
      {/* Header */}
      <header className="bg-blue-300 text-white text-center py-4">
        <h1 className="text-xl font-bold">VráťsaSpäť</h1>
      </header>

      {/* Užívateľské informácie */}
      <div className="container mx-auto mt-4 p-4 bg-white shadow-md rounded-lg">
        <h2 className="text-lg font-bold text-gray-800" id="user-name">Vaše prevádzky</h2>
        
        {/* Zoznam prevádzok */}
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {businesses.length > 0 ? (
            businesses.map((business) => (
              <div key={business.businessId} className="bg-gray-100 shadow-md p-4 rounded-lg">
                <h4 className="text-lg font-bold text-gray-800">{business.businessName}</h4>
                <p className="text-gray-600">Body: <span>{business.points}</span></p>
              </div>
            ))
          ) : (
            <p className="text-gray-600 text-center mt-4 col-span-3">Momentálne nie ste registrovaný u žiadneho podniku.</p>
          )}
        </div>
        
        {/* QR kód */}
        {qrCodeUrl && (
          <div className="mt-6 flex justify-center">
            <div className="bg-white rounded-lg p-4 shadow-md text-center">
              <h3 className="text-xl font-semibold text-blue-800 mb-2">Váš QR kód</h3>
              <img src={qrCodeUrl} alt="QR kód" className="w-48 h-48 mx-auto" />
            </div>
          </div>
        )}


      </div>
    </div>
  );
};

export default UserDashboard;

