import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signOut as authSignOut, signInWithRedirect, setPersistence, browserSessionPersistence, getRedirectResult } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAw5G-vi_ye8KQC64dNupUkd1oNnksb5mc",
  authDomain: "vratsaspat.sk",
  databaseURL: "https://loyalperks-1-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "loyalperks-1",
  storageBucket: "loyalperks-1.appspot.com",
  messagingSenderId: "919645437293",
  appId: "1:919645437293:web:85b4d64c791d6801266e64"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const storage = getStorage(app);

// Configure Firebase persistence
setPersistence(auth, browserSessionPersistence);

// Google Sign-In provider
const googleProvider = new GoogleAuthProvider();

// Function to initiate Google sign-in
const signInWithGoogle = async () => {
  try {
    await signInWithRedirect(auth, googleProvider);
  } catch (error) {
    console.error('Google sign-in error:', error);
    throw error;
  }
};

// Function to handle redirect result and get the access token
const handleRedirectResult = async () => {
  try {
    const result = await getRedirectResult(auth);
    if (result) {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      const user = result.user;

      // Store the token and user info as needed
      if (token) {
        localStorage.setItem('accessToken', token);
      }
      console.log('User:', user);
    }
  } catch (error) {
    console.error('Error handling redirect result:', error);
  }
};

// Function to sign out the user
const signOut = async () => {
  try {
    await authSignOut(auth);
  } catch (error) {
    console.error('Sign out error:', error);
    throw error;
  }
};

export { auth, database, signInWithGoogle, handleRedirectResult, signOut, storage };
