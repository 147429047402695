import * as React from "react";
import { useState, useEffect } from 'react';
import { auth, database, storage } from './firebaseConfig';
import { ref, get, set } from 'firebase/database';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { User, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

const SettingsAdmin: React.FC = () => {
    const [businessName, setBusinessName] = useState<string>('');
    const [points, setPoints] = useState<number>(0);
    const [qrCodeUrl, setQrCodeUrl] = useState<string>('');
    const [currentPassword, setCurrentPassword] = useState<string | null>(null); // Nullable string for password
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    // Získať údaje o prevádzke z Realtime Database
    const fetchBusinessData = async () => {
        const user = auth.currentUser;
        if (user) {
            const businessRef = ref(database, `businesses/${user.uid}`);
            const snapshot = await get(businessRef);
            if (snapshot.exists()) {
                const data = snapshot.val();
                setBusinessName(data.businessName);
                setPoints(data.points);
            }
        }
    };

    // Získať URL QR kódu z Realtime Database
    const fetchQrCodeUrl = async () => {
        const user = auth.currentUser;
        if (user) {
            const qrCodeRef = ref(database, `businesses/${user.uid}/qrCodeUrl`);
            const snapshot = await get(qrCodeRef);
            if (snapshot.exists()) {
                setQrCodeUrl(snapshot.val());
            } else {
                setQrCodeUrl('');
            }
        }
    };

    const reauthenticateUser = async () => {
        const user = auth.currentUser;
        if (user && user.email && typeof currentPassword === 'string') {
            const credential = EmailAuthProvider.credential(user.email, currentPassword);
            try {
                await reauthenticateWithCredential(user, credential);
                return true;
            } catch (error: any) { // Type assertion pre 'error' ako 'any'
                setMessage(`Chyba při přihlašování: ${(error as Error).message}`);
                return false;
            }
        } else {
            setMessage('Chyba: Nesprávne heslo.');
            return false;
        }
    };
    
    // Změna hesla
    const changePassword = async () => {
        try {
            if (newPassword !== confirmNewPassword) {
                setMessage("Heslá sa nezhodujú. Skontrolujte zadané heslá.");
                return;
            }

            const user = auth.currentUser;
            if (user && currentPassword) { // Ensure currentPassword is not null
                const isAuthenticated = await reauthenticateUser();
                if (isAuthenticated) {
                    await updatePassword(user, newPassword);
                    setMessage('Heslo bolo úspešne zmenené.');
                    setTimeout(() => {
                        setMessage('');
                        setCurrentPassword('');
                        setNewPassword('');
                        setConfirmNewPassword('');
                    }, 2000);
                }
            }
        } catch (error: any) {
            setMessage(`Chyba pri zmene hesla: ${error.message}`);
        }
    };

    // Změna názvu prevádzky
    const handleBusinessNameChange = async (newName: string) => {
        const user = auth.currentUser;
        if (user) {
            const businessRef = ref(database, `businesses/${user.uid}/businessName`);
            try {
                await set(businessRef, newName);
                setBusinessName(newName);
                setMessage('Názov prevádzky bol úspešne zmenený.');
                setTimeout(() => setMessage(''), 2000);
            } catch (error: any) {
                setMessage(`Chyba pri zmene názvu prevádzky`);
            }
        }
    };

    // Změna počtu bodů
    const handlePointsChange = async (newPoints: number) => {
        const user = auth.currentUser;
        if (user) {
            newPoints = Math.max(1, newPoints);
            const pointsRef = ref(database, `businesses/${user.uid}/points`);
            try {
                await set(pointsRef, newPoints);
                setPoints(newPoints);
                setMessage('Počet bodov bol úspešne zmenený.');
                setTimeout(() => setMessage(''), 2000);
            } catch (error: any) {
                setMessage(`Chyba pri zmene počtu bodov`);
            }
        }
    };

    // Stiahnuť QR kód z Firebase Storage
    const downloadQRCode = async () => {
        try {
            const qrCodeStorageRef = storageRef(storage, qrCodeUrl);
            const downloadUrl = await getDownloadURL(qrCodeStorageRef);
            window.open(downloadUrl, '_blank');
        } catch (error: any) {
            setMessage(`Chyba pri sťahovaní QR kódu: ${error.message}`);
        }
    };

    // Effekt pro načítání údajů o prevádzce a QR kódu při načtení komponenty
    useEffect(() => {
        fetchBusinessData();
        fetchQrCodeUrl();
    }, []);

    return (
        <div className="bg-blue-50 flex flex-col h-screen overflow-y-auto">
            <header className="bg-blue-300 text-white text-center py-4">
                <h1 className="text-xl font-bold">Nastavenia</h1>
            </header>

            <div className="flex-grow overflow-y-auto">
                <div className="bg-white shadow-md mx-4 mt-4 p-4 rounded-lg pb-20">
                    <h2 className="text-lg font-bold text-gray-800">Nastavenia prevádzky</h2>

                    <div className="mt-4">
                        <label htmlFor="business-name" className="block text-gray-600">Názov prevádzky</label>
                        <input type="text" id="business-name" value={businessName} onChange={(e) => setBusinessName(e.target.value)} className="w-full px-4 py-2 rounded-lg border border-gray-300 outline-none mt-1" />
                        <button onClick={() => handleBusinessNameChange(businessName)} className="bg-blue-300 text-white py-1 px-4 rounded-lg mt-2">
                            Uložiť
                        </button>
                    </div>

                    <div className="mt-4">
                        <label htmlFor="increase-points" className="block text-gray-600">Zadajte počet bodov na navýšenie (min. 1)</label>
                        <input type="number" id="increase-points" value={points} onChange={(e) => setPoints(parseInt(e.target.value))} min={1} className="w-full px-4 py-2 rounded-lg border border-gray-300 outline-none mt-1" />
                        <button onClick={() => handlePointsChange(points)} className="bg-blue-300 text-white py-1 px-4 rounded-lg mt-2">
                            Uložiť
                        </button>
                    </div>

                    <div className="mt-4">
                        <label htmlFor="current-password" className="block text-gray-600">Aktuálne heslo</label>
                        <input type="password" id="current-password" value={currentPassword || ''} onChange={(e) => setCurrentPassword(e.target.value)} className="w-full px-4 py-2 rounded-lg border border-gray-300 outline-none mt-1" />
                    </div>

                    <div className="mt-4">
                        <label htmlFor="new-password" className="block text-gray-600">Nové heslo</label>
                        <input type="password" id="new-password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="w-full px-4 py-2 rounded-lg border border-gray-300 outline-none mt-1" />
                    </div>

                    <div className="mt-4">
                        <label htmlFor="confirm-new-password" className="block text-gray-600">Potvrďte nové heslo</label>
                        <input type="password" id="confirm-new-password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} className="w-full px-4 py-2 rounded-lg border border-gray-300 outline-none mt-1" />
                    </div>

                    <div className="mt-4">
                        <button onClick={changePassword} className="bg-blue-300 text-white py-2 px-4 rounded-lg">
                            Zmeniť heslo
                        </button>
                        {newPassword !== confirmNewPassword && (
                            <div className="text-red-600 mt-1">Heslá sa nezhodujú. Skontrolujte zadané heslá.</div>
                        )}
                        {message && (
                            <div className={`mt-2 ${message.startsWith('Chyba') ? 'text-red-600' : 'text-green-600'}`}>{message}</div>
                        )}
                    </div>
                </div>

                <div className="bg-white shadow-md mx-4 mt-4 p-4 rounded-lg">
                    <h2 className="text-lg font-bold text-gray-800">QR kód prevádzky</h2>

                    {qrCodeUrl ? (
                        <div className="mt-4">
                            <img src={qrCodeUrl} alt="QR Code" className="mx-auto" style={{ maxWidth: '100%' }} />
                            <button onClick={downloadQRCode} className="bg-blue-300 text-white py-2 px-4 rounded-lg mt-2 block mx-auto">
                                Stiahnuť QR kód
                            </button>
                        </div>
                    ) : (
                        <div className="mt-4 text-gray-600 text-center">QR kód ešte nebol vygenerovaný.</div>
                    )}

                    {message && (
                        <div className={`mt-2 ${message.startsWith('Chyba') ? 'text-red-600' : 'text-green-600'}`}>{message}</div>
                    )}
                </div>

                {/* Tlačidlo na odhlásenie */}
                <div className="flex justify-center mt-6">
                        <a className="bg-red-500 text-white py-2 px-6 rounded-full shadow-md" href="/business/logout">
                            Odhlásiť sa
                        </a>
                    </div>
                </div>
                <br />
                <br />
                <br />

                {/* Menu */}
                <nav className="bg-white shadow-md fixed bottom-0 left-0 right-0 flex justify-around py-2">
                    <a href="/business/settings" className="flex flex-col items-center text-blue-600">
                    <img src="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/icons/gear.svg" alt="Nastavenia" className="w-6 h-6" />
                    <span className="text-sm">Nastavenia</span>
                    </a>
                    <a href="/business/admin" className="flex flex-col items-center text-gray-7000">
                    <img src="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/icons/house-door.svg" alt="Domov" className="w-6 h-6" />
                    <span className="text-sm">Domov</span>
                    </a>
                    <a href="/business/email-list" className="flex flex-col items-center text-gray-700">
                    <img src="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/icons/envelope.svg" alt="Emailová Databáza" className="w-6 h-6" />
                    <span className="text-sm">Zákazníci</span>
                    </a>
                </nav>
        </div>
    );
};

export default SettingsAdmin;
