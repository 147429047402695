import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthComponent from './Register';
import Business from './Business';
import UserDashboard from './UserDashboard';
import UserLogin from './UserLogin';
import AdminLogout from './AdminLogout';
import AdminInterface from './AdminInterface';
import UserLogout from './UserLogout';
import { auth, handleRedirectResult } from './firebaseConfig'; // Import Firebase authentication instance and handleRedirectResult function
import { Loading } from './Loading';
import BusinessTrial from './BusinessTrial';
import Home from './Home';
import EmailList from './EmailList';
import SettingsAdmin from './Settings';

const AppRouter: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const checkAuthState = async () => {
      await handleRedirectResult(); // Handle the redirect result to check for user login
      const unsubscribe = auth.onAuthStateChanged(user => {
        setIsAuthenticated(!!user); // Update isAuthenticated based on user authentication state
      });

      return unsubscribe;
    };

    const unsubscribe = checkAuthState();

    return () => {
      if (unsubscribe instanceof Promise) {
        unsubscribe.then(unsub => unsub());
      }
    };
  }, []);

  if (isAuthenticated === null) {
    return <Loading />; // Optional: Show loading indicator while checking authentication state
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/business/auth" element={<AuthComponent />} />
        <Route path="/business/email-list" element={<EmailList />} />
        <Route path="/business/settings" element={<SettingsAdmin />} />
        <Route path="/business/admin" element={<AdminInterface />} />
        <Route path="/business/logout" element={<AdminLogout />} />
        <Route path='/business/trial-start' element={<BusinessTrial />} />
        <Route path="/business/:id" element={<Business />} />

        {/* Protected route for UserDashboard */}
        <Route
          path="/user/dashboard"
          element={isAuthenticated ? <UserDashboard /> : <Navigate to="/user/login" />}
        />

        {/* Route for UserLogin */}
        <Route path="/user/login" element={<UserLogin />} />

        {/* Route for UserLogout */}
        <Route path="/user/logout" element={<UserLogout />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
