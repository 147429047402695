import React, { useState, useEffect } from "react";
import { Link } from 'react-scroll';

const Home = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleLinkClick = () => {
      closeMobileMenu();
    };

    const menuLinks = document.querySelectorAll('.mobile-menu-link');
    menuLinks.forEach(link => {
      link.addEventListener('click', handleLinkClick);
    });

    return () => {
      menuLinks.forEach(link => {
        link.removeEventListener('click', handleLinkClick);
      });
    };
  }, [isMobileMenuOpen]);

  return (
    <div className="font-sans antialiased text-gray-900 bg-gray-50">
      {/* Header */}
      <header className="bg-blue-300 text-white sticky top-0">
        <div className="container mx-auto flex justify-between items-center py-4 px-6">
          <h1 className="text-2xl font-bold">VráťsaSpäť</h1>
          <div className="block lg:hidden">
            <button
              className="text-white focus:outline-none"
              onClick={toggleMobileMenu}
            >
              <i className={`bi ${isMobileMenuOpen ? 'bi-x' : 'bi-list'} text-2xl`}></i>
            </button>
          </div>
          <nav
            className={`${
              isMobileMenuOpen
                ? 'lg:block absolute bg-blue-300 top-full left-0 right-0 transition-all duration-300 z-10'
                : 'hidden lg:flex lg:space-x-6'
            }`}
          >
            <ul className="lg:flex lg:space-x-6 lg:justify-center lg:items-center lg:h-full pl-4">
              <li>
                <Link
                  to="o-nas"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="block py-2 lg:py-0 hover:text-gray-200 mobile-menu-link"
                  onClick={closeMobileMenu}
                >
                  O nás
                </Link>
              </li>
              <li>
                <Link
                  to="vyhody"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="block py-2 lg:py-0 hover:text-gray-200 mobile-menu-link"
                  onClick={closeMobileMenu}
                >
                  Výhody
                </Link>
              </li>
              <li>
                <Link
                  to="eco-friendly"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="block py-2 lg:py-0 hover:text-gray-200 mobile-menu-link"
                  onClick={closeMobileMenu}
                >
                  Eco Friendly
                </Link>
              </li>
              <li>
                <Link
                  to="loyalita"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="block py-2 lg:py-0 hover:text-gray-200 mobile-menu-link"
                  onClick={closeMobileMenu}
                >
                  Loyalita
                </Link>
              </li>
              <li>
                <Link
                  to="cena"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="block py-2 lg:py-0 hover:text-gray-200 mobile-menu-link"
                  onClick={closeMobileMenu}
                >
                  Cena
                </Link>
              </li>
              <li>
                <Link
                  to="nastavenie"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="block py-2 lg:py-0 hover:text-gray-200 mobile-menu-link"
                  onClick={closeMobileMenu}
                >
                  Nastavenie
                </Link>
              </li>
              <li>
                <Link
                  to="faq"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="block py-2 lg:py-0 hover:text-gray-200 mobile-menu-link"
                  onClick={closeMobileMenu}
                >
                  FAQ
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {/* O nás */}
      <section id="o-nas" className="py-12 bg-blue-100">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">O nás</h2>
          <p className="text-lg mb-6 text-center">
            Poskytujeme jednoduché virtuálne kartičky pre váš podnik. Stačí si iba vytvoriť účet zdarma, stiahnuť QR kód a vložiť si ho do prevádzky. Každý zákazník, ktorý si naskenuje QR kód, sa pridá do vašej emailovej databáze a začne zbierať body na vašu prevádzku, za ktoré si môže vyzdvihnúť odmeny.
          </p>
          <div className="text-center">
            <Link
              to="cena"
              spy={true}
              smooth={true}
              duration={500}
              className="inline-block bg-blue-400 text-white py-2 px-4 rounded hover:bg-blue-500 mobile-menu-link"
            >
              Vytvoriť si účet zdarma
            </Link>
          </div>
        </div>
      </section>

      {/* Výhody */}
      <section id="vyhody" className="py-12">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">Výhody</h2>
          <ul className="text-lg space-y-4 text-blue-800">
            <li className="flex items-center">
              <i className="bi bi-check-circle-fill text-green-500 mr-2"></i>Veľmi jednoduché založenie účtu
            </li>
            <li className="flex items-center">
              <i className="bi bi-check-circle-fill text-green-500 mr-2"></i>Pridávanie bodov zákazníkom je veľmi jednoduché, pretože každý zákazník má svoj QR kód, ktorý po naskenovaní zobrazí počet bodov, ktoré mu môžete zvýšiť alebo vynulovať (napríklad, keď si chce vyzdvihnúť odmenu)
            </li>
            <li className="flex items-center">
              <i className="bi bi-check-circle-fill text-green-500 mr-2"></i>Budovanie emailovej databázy prevádzky, ktorú môže prevádzka využiť na promo akcie a podobné
            </li>
          </ul>
        </div>
      </section>

      {/* Eco Friendly */}
      <section id="eco-friendly" className="py-12 bg-blue-100">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">Eco Friendly</h2>
          <p className="text-lg mb-6 text-center text-blue-800">
            V dnešnej dobe každý stráca svoje papierové vernostné kartičky, a tak toto eco-friendly riešenie je moderné a zjednoduší systém zákazníkom aj vám.
          </p>
        </div>
      </section>

      {/* Loyalita */}
      <section id="loyalita" className="py-12">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">Vybudovanie si lojálneho zákazníka</h2>
          <p className="text-lg mb-6 text-center text-blue-800">
            Naša služba vám pomôže vybudovať si lojalitu zákazníkov tým, že im umožní zbierať body a vyzdvihovať si odmeny za ich vernosť. Týmto spôsobom sa zvyšuje spokojnosť a opakovaný návrat zákazníkov do vašej prevádzky.
          </p>
        </div>
      </section>

      {/* Cena */}
      <section id="cena" className="py-12 bg-blue-100">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl lg:text-4xl font-bold mb-6 text-center text-blue-600">Cena</h2>
          <div className="grid gap-8 lg:grid-cols-3">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h3 className="text-xl lg:text-2xl font-bold mb-2 lg:mb-4 text-blue-600">Mesačný balíček</h3>
              <p className="text-3xl lg:text-4xl font-bold mb-2 lg:mb-4 text-blue-800">9,60 €</p>
              <p className="text-gray-600 mb-4">s DPH mesačne</p>
              <p className="text-gray-600 mb-4">(platba dopredu bez záväzkov)</p>
              <a href="/business/auth" className="block w-full lg:inline-block lg:w-auto bg-blue-400 text-white py-2 px-4 rounded-lg hover:bg-blue-500">Vybrať</a>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h3 className="text-xl lg:text-2xl font-bold mb-2 lg:mb-4 text-blue-600">Polročný balíček</h3>
              <p className="text-3xl lg:text-4xl font-bold mb-2 lg:mb-4 text-blue-800">54 €</p>
              <p className="text-gray-600 mb-4">(9 € mesačne) s DPH</p>
              <p className="text-gray-600 mb-4">(platí sa pol roka dopredu)</p>
              <a href="/business/auth" className="block w-full lg:inline-block lg:w-auto bg-blue-400 text-white py-2 px-4 rounded-lg hover:bg-blue-500">Vybrať</a>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h3 className="text-xl lg:text-2xl font-bold mb-2 lg:mb-4 text-blue-600">Ročný balíček</h3>
              <p className="text-3xl lg:text-4xl font-bold mb-2 lg:mb-4 text-blue-800">103,20 €</p>
              <p className="text-gray-600 mb-4">(8,60 € mesačne) s DPH</p>
              <p className="text-gray-600 mb-4">(platí sa 1 rok dopredu)</p>
              <a href="/business/auth" className="block w-full lg:inline-block lg:w-auto bg-blue-400 text-white py-2 px-4 rounded-lg hover:bg-blue-500">Vybrať</a>
            </div>
          </div>
          <div className="text-center mt-6">
            <p className="text-lg lg:text-xl text-blue-800">Skúšobná verzia na 1 mesiac zadarmo bez záväzkov.</p>
            <a href="/business/trial-start" className="inline-block mt-4 bg-blue-400 text-white py-2 px-4 rounded-lg hover:bg-blue-500">Skúsiť zdarma bez záväzkov</a>
          </div>
        </div>
      </section>

      {/* Nastavenie */}
      <section id="nastavenie" className="py-12">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">Nastavenie</h2>
          <p className="text-lg mb-6 text-center text-blue-800">
            Jednoducho si vytvorte účet a my vám poskytneme všetky potrebné nástroje na nastavenie. V prípade potreby vám radi zadarmo pomôžeme s inštaláciou a nastavením systému.
          </p>
        </div>
      </section>

      {/* FAQ */}
      <section id="faq" className="py-12 bg-blue-100">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">FAQ</h2>
          <ul className="text-lg space-y-4 text-blue-800">
            <li>
              <h3 className="font-bold">Čo sa stane po skúšobnej mesačnej dobe?</h3>
              <p>Po skončení skúšobnej mesačnej doby, ak máte záujem pokračovať, vystavíme vám faktúru podľa zvoleného balíčka. Ak nemáte záujem, stačí službu prestať používať.</p>
            </li>
            <li>
              <h3 className="font-bold">Môžem program kedykoľvek zrušiť?</h3>
              <p>Áno, program môžete kedykoľvek zrušiť bez akýchkoľvek záväzkov.</p>
            </li>
            <li>
              <h3 className="font-bold">Sú všetky platby vrátane DPH?</h3>
              <p>Áno, všetky uvedené ceny zahŕňajú DPH.</p>
            </li>
          </ul>
        </div>
      </section>

      

     {/* Footer */}
      <footer className="bg-blue-300 text-white py-4">
        <div className="container mx-auto text-center">
          <p>
            MELMAK, s.r.o. | IČO: 50438131 | DIČ: 2120324921 | IČ DPH: SK2120324921 <br />
            <a href="/terms.pdf" target="_blank" className="text-white underline">
              Obchodné podmienky
            </a> <br />
            info@vratsaspat.sk
          </p>
        </div>
      </footer>

    </div>
  );
};

export default Home;
